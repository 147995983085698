<template>
    <div>
        <card-header title="Edit Flight" icon="fa-plane-departure"/>

        <card-body>
            <reg-hotel-form v-if="registration.id" :hotel="hotel" :registration="registration"/>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteHotel"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Hotel?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" :disabled="!hotel.hotel || !hotel.room_type_id || !hotel.start_date || !hotel.start_time || !hotel.end_date || !hotel.end_time" @click="submit"><i class="fas fa-check mr-2"></i>Save Hotel</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import {client as http} from '../../http_client';
    import CardHeader from '../../TIER/components/CardHeader';
    import CardBody from '../../TIER/components/CardBody';
    import CardFooter from '../../TIER/components/CardFooter';
    import FlightForm from './FlightForm';
    import RegistrationListItem from "../../components/RegistrationListItem";

    import async from 'async';
    import CardList from "../../TIER/components/CardList";
    import {mapActions} from "vuex";
    import RegHotelForm from "./HotelForm";

    export default {
        props: ['card', 'props'],
        components: {RegHotelForm, CardList, RegistrationListItem, CardFooter, CardBody, CardHeader},
        data: function () {
            return {
                hotel: {
                    hotel: null,
                    room_type_id: null,
                    start_date: null,
                    start_time: null,
                    end_date: null,
                    end_time: null
                },
                registration: {trip: {}, user: {}},
                confirmDelete: false,
                dirty: null
            };
        },
        methods: {
            loadHotel: function () {
                this.$emit('loading', true);
                this.dirty = null;

                http.get('/api/registrations/' + this.props.registrationId + '/hotels/' + this.props.hotelId, {force: true}).then(response => {
                    response.data.hotel = {};
                    this.hotel = response.data;

                    http.get('/api/registrations/' + this.props.registrationId, {force: true}).then(response => {
                        this.registration = response.data;
                        this.$emit('loading', false);
                    });
                });
            },
            submit() {
                this.$emit('loading', true);
                this.hotel.hotel_id = this.hotel.hotel.id;

                http.put('/api/registrations/' + this.props.registrationId + '/hotels/' + this.props.hotelId, this.hotel, {force: true}).then(response => {
                    this.$reloadCard('registrations');
                    this.$reloadCard('registration', {registrationId: this.props.registrationId});
                    this.$reloadCard('reg-hotels', {registrationId: this.props.registrationId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteHotel() {
                if (this.confirmDelete) {
                    http.delete('/api/registrations/' + this.props.registrationId + '/hotels/' + this.props.hotelId).then(response => {
                        this.$reloadCard('reg-hotels', {registrationId: this.props.registrationId});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            }
        },
        watch: {
            'props.hotelId': function (newProps) {
                this.loadHotel();
            },
            'hotel': {
                deep: true,
                handler: function () {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this hotel? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadHotel();
        }
    };
</script>
